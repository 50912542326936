<template lang="">
  <div>
    <h6 class="heading-small text-muted mb-4">ข้อมูลร้านค้า</h6>
    <div class="pl-lg-4">
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">ชื่อ</label>
                <base-input
                  name="name"
                  placeholder="ชื่อ"
                  v-model="item.name"
                  :rules="{ required: true }"
                ></base-input>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">เว็บไซต์</label>
                <base-input
                  name="website"
                  placeholder="เว็บไซต์"
                  v-model="item.website"
                  :rules="{ required: true }"
                ></base-input>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">เบอร์โทรศัพท์</label>
                <base-input
                  name="phone"
                  v-number-only
                  :rules="{ required: true }"
                  placeholder="เบอร์โทรศัพท์"
                  v-model="item.phone"
                ></base-input>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">อีเมล</label>
                <base-input
                  name="email"
                  type="email"
                  placeholder="อีเมล"
                  v-model="item.email"
                  :rules="{ required: true }"
                ></base-input>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label optional">ที่อยู่</label>
                <b-form-textarea
                  name="address"
                  placeholder="ที่อยู่"
                  v-model="item.address"
                  rows="3"
                  max-rows="5"
                ></b-form-textarea>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label optional"
                  >ชื่อที่อยู่สำหรับใบกำกับภาษี</label
                >
                <b-form-textarea
                  name="name_address"
                  placeholder="ชื่อที่อยู่สำหรับใบกำกับภาษี"
                  v-model="item.name_address"
                  rows="3"
                  max-rows="5"
                ></b-form-textarea>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">เลขประจำตัวผู้เสียภาษี</label>
                <base-input
                  name="tax_id"
                  placeholder="เลขประจำตัวผู้เสียภาษี"
                  v-model="item.tax_id"
                  :rules="{ required: true }"
                ></base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <hr class="my-4" />
        </div>

        <div class="col-12">
          <h6 class="heading-small text-muted mb-4">Social Media</h6>
          <div class="row">
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label optional">เฟซบุ๊ค</label>
                <base-input
                  name="facebook"
                  placeholder="เฟซบุ๊ค"
                  v-model="item.facebook"
                ></base-input>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">ไลน์</label>
                <base-input
                  name="line"
                  placeholder="ไลน์"
                  v-model="item.line"
                  :rules="{ required: true }"
                ></base-input>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label optional">อินสตาแกรม</label>
                <base-input
                  name="instagram"
                  placeholder="อินสตาแกรม"
                  v-model="item.instagram"
                ></base-input>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label optional">ทวิตเตอร์</label>
                <base-input
                  name="twitter"
                  placeholder="ทวิตเตอร์"
                  v-model="item.twitter"
                ></base-input>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label optional">ยูทูป</label>
                <base-input
                  name="youtube"
                  placeholder="ยูทูป"
                  v-model="item.youtube"
                ></base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <h6 class="heading-small text-muted mb-4">Setting</h6>
          <div class="row">
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">Template</label>
                <select class="form-control" v-model="item.template_id">
                  <option
                    v-for="template in templates"
                    :key="`template_key_${template.id}`"
                    :value="template.id"
                  >
                    {{ template.name }}
                  </option>
                </select>
                <base-input
                  name="template_id"
                  v-model="item.template_id"
                  class="custom-input-valid-datepicker"
                ></base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <h6 class="heading-small text-muted mb-4">Setting SMTP</h6>
          <div class="row">
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">SMTP Host</label>
                <base-input
                  name="smtp_host"
                  placeholder="SMTP Host"
                  v-model="item.smtp_host"
                ></base-input>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">SMTP Port</label>
                <base-input
                  name="smtp_port"
                  placeholder="SMTP Port"
                  v-model="item.smtp_port"
                ></base-input>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">SMTP Username</label>
                <base-input
                  name="smtp_user"
                  placeholder="SMTP Username"
                  v-model="item.smtp_user"
                ></base-input>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">SMTP Password</label>
                <base-input
                  name="smtp_pass"
                  placeholder="SMTP Password"
                  v-model="item.smtp_pass"
                ></base-input>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">รูปภาพ</label>
                <div class="col-sm-8">
                  <!-- <div
                    class="card"
                    style="border: 1px solid #cad1d7; border-radius: 5px;"
                  >
                    <div
                      class="card-body m-auto"
                      style="height: 230px; overflow: auto;"
                    > -->
                  <div>
                    <input
                      type="file"
                      id="fileUpload"
                      accept="image/*"
                      style="display: none"
                      @change="uploadFileChangeHandler"
                    />
                    <img
                      :src="funcs.isImgEmpty(displayImg)"
                      class="img-thumbnail"
                      style="max-width: 180px"
                      @click="uploadFileHandler()"
                    />
                  </div>

                  <!-- </div>
                  </div> -->
                  <div
                    class="invalid-feedback"
                    style="display: block"
                    v-if="item.logo_url === null"
                  >
                    The priority field is required
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <h6 class="heading-small text-muted mb-4">Code Setting</h6>
          <div class="row">
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">Type</label>
                <select class="form-control" v-model="item.prefix_type">
                  <option value="0">Default (O-XXXXX)</option>
                  <option value="1">Prefix + Number</option>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">Prefix</label>
                <base-input
                  name="prefix"
                  v-model="item.prefix"
                ></base-input>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="mb-1">
                <label class="form-label">Counter</label>
                <base-input
                  name="prefx_counter"
                  v-model="item.prefix_counter"
                ></base-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "store-view-form-store",
  props: ["item", "funcs", "imageDeletes"],
  data() {
    return {
      templates: [],
      file: "",
      displayImg: ""
    };
  },
  methods: {
    async getTemplate() {
      this.templates = await this.HttpServices.getMasterData(
        "/master/getStoreTemplates"
      );
    },
    uploadFileHandler() {
      $("#fileUpload").trigger("click");
    },
    uploadFileChangeHandler(event) {
      const vm = this;
      let img = event.target.files;
      if (img.length > 0) {
        var tmpImgs = [];
        for (let i = 0; i < img.length; i++) {
          const reader = new FileReader();
          reader.onload = e => {
            vm.displayImg = e.target.result
            tmpImgs.push(e.target.result);
          };
          reader.readAsDataURL(img[i]);
        }

        vm.item.logo_url = img[0];
      } else {
        this.displayImg = null;
        this.file = null;
      }
    }
  },
  async mounted() {
    await this.getTemplate();
  },
  watch: {
    "item.logo_url"(newVal) {
      if (newVal) {
        this.displayImg = "" + newVal.url;
      } else {
        this.displayImg = "";
      }
    }
  }
};
</script>
