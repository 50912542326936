<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header">
            <b-col cols="8">
              <h2 class="mb-0">
                <i class="fa fa-plus"></i> เพิ่มข้อมูลร้านค้า
              </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(addHandler)">
              <form-store :item="item" :funcs="funcs"/>
              <div class="float-right d-flex">
                <span
                  ><router-link :to="'/store'" class="nav-link text-underline">
                    กลับ</router-link
                  ></span
                >
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button
                  variant="primary"
                  type="submit"
                  class="style_btn"
                  v-if="permission.actionData.flag_insert"
                  ><i class="fa fa-check"></i> บันทึก</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formStore from "./form.store";
import * as scripts from "../../../util/scripts";
export default {
  name: "store-view-page-store-add",
  data() {
    return {
      permission: {
        appSlug: "store",
        actionData: {}
      },
      item: {
        id: "",
        name: "",
        website: "",
        address: "",
        phone: "",
        email: "",
        facebook: "",
        instagram: "",
        line: "",
        name_address: "",
        tax_id: "",
        twitter: "",
        youtube: ""
      },
      funcs: scripts.funcs
    };
  },
  methods: {
    async addHandler() {
      const result = await this.HttpServices.postData("/ab_store", this.item);
      if (result && result.status.code == "200") {
        this.AlertUtils.alertCallback("success", `บันทึกสำเร็จ`, () => {
          this.$router.push("/store");
        });
        this.$emit("successFuction", "");
      } else {
        this.AlertUtils.alert("warning", result.status.message);
      }
    },
    onBackHandler() {
      this.$router.push("/store");
    }
  },
  components: {
    formStore
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
  }
};
</script>
